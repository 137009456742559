.cardContainer {
    width: 600px;
    padding: 0 !important;
}
.header {
    font-size: 1.8rem;
    padding-bottom: 1rem;
}
.uploadContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.listIcon {
    color: #1976D2;
}
.listContentHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.listContentFlex {
    display: flex;
    align-items: center;
}
.listIcon {
    margin-right: 8px;
}   
.listContent {
    margin-left: 8px;
}
.listGap {
    height: 35px;
    background-color: #EEEEEE;
}
.listContentHolder {
    padding: 10px 0px;
}
.printContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.btnHolder {
    padding-top: 1rem;
    align-self: flex-end;
}
.printFlex {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-bottom: 2rem;
}
@media screen and (max-width:700px) {
    .cardContainer {
        width: calc(100% - 30px);
    }
    .printFlex {
        width: calc(100% - 30px);
    }
    .header {
        font-size: 1.3rem;
    }
}
