.navMenuContainer {
  background-color: #f5f5f5;
  height: 100vh;
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.navMenuContainer2 {
  background-color: #f5f5f5;
  height: 100vh;
  position: fixed;
  width: 10%;
  overflow-y: scroll;
}
