.container {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.header {
    font-size: 1.2rem;
    font-weight: bold;
    color: #005A5F;
}
.btnContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}