.container {
  /* margin: 0 2rem; */
}
.orderDetailsContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  flex-wrap: wrap;
  row-gap: 10px;
}
.cardHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
}
.cardContainer {
  margin: 1rem 0;
}
.filterContainer {
  display: flex;
  gap: 1rem;
  padding-top: 20px;
  padding-bottom: 10px;
}
.modalBtn {
  display: flex;
  justify-content: flex-end;
}
.modalContainer {
  font-weight: bold;
  width: 500px;
}
.fileConfigContainer {
  padding: 1rem;
  padding-left: 7px;
}
.fileConfigContainer_paper {
  margin: 1rem 0;
}
.totalCostPaper {
  padding: 1rem;
  padding-left: 7px;
}
.paymentStatus {
}
.feedBackContainer {
  margin: 10px 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.commentContainer {
  display: flex;
  gap: 1rem;
}
