.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.tableHeader {
    background-color:#005A5F;
}
.tableRowHeader > * {
    color: white !important;
}
.tableData {
    font-size: 1.05rem !important;
}
.header {
    font-weight: bold;
}