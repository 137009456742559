.container {
margin-top: 5%;
}
.radioLabel {
    display: block;
    font-weight: bold;
    margin-top: 10px;
}
.form {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.btnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.menu{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
.formContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.mdRadioLabel {
    font-weight: bold !important;
    color: black !important;
}
.selectHeader {
}
.paper {
    /* width: 100%; */
    padding: 3rem;
}
.required {
    color: red;
}


.listIcon {
    color: #1976D2;
}
.listContentHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.listContentFlex {
    display: flex;
    align-items: center;
}
.listIcon {
    margin-right: 8px;
}   
.listContent {
    margin-left: 8px;
}
.listContentHolder {
    /* padding: 10px 0px; */
}
.printContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.btnHolder {
    padding-top: 1rem;
    align-self: flex-end;
}
.listGap {
    /* height: 35px; */
    background-color:red;
}