.container {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.formContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.searchField {
  width: 300px !important;
}
