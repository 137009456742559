.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.textFieldContainer {
  display: flex;
  gap: 1rem;
}
.selectContainer {
  font-weight: bold;
  width: 500px;
}
.modalContent {
  font-weight: bold;
}
.fileConfigContainer {
  padding: 1rem;
  padding-left: 7px;
}
.fileConfigContainer_paper {
  margin: 1rem 0;
}
.auditContainer {
  display: flex;
  padding: 1rem;
  font-weight: bold;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 20px;
}
