/* .container {
  width: 100%;
  height: calc(100vh - 80px - 16px);
}

.header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storeContainer {
  margin-left: 2%;
  height: 75vh;
  overflow: scroll;
  min-width: 250px;
  margin-bottom: 10px;
   overflow-x: hidden; 
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: bold;
}

.costContainer {
  padding: 1rem;
}

  
  .storeContainer {
    height: 300px;
    width: 300px;
    overflow: scroll;
     overflow-x: scroll; 
  }


  @media screen and (min-width: 1000px) {
  .mainFlex {
    display: flex;
    flex-wrap: wrap;
  }

  .storeHolder {
    width: 25%;
    overflow: scroll;
    overflow-x: scroll;
  
  }

  .mapContainer {
    width: 75%;
    height: calc(100vh - 80px - 16px);
  }
}  


@media screen and (max-width: 600px) and (max-width: 300px) {
  .mainFlex {
    flex-direction: column-reverse;
  }

  .mapContainer {
    width: 100%;
    height: 300px;
  }

  .storeHolder {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
}  */

.container {
  width: 100%;
  height: calc(100vh - 80px - 16px);
}

.header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  font-size: 40px;

  font-weight: bold;
  margin-top: 3px;
  align-items: center;
}

.storeContainer {
  margin-left: 2%;
  height: calc((100vh - 80px - 16px) * 0.95);
  overflow: scroll;
  overflow-x: hidden;
  max-width: 400px;
}
.storeContainerBox {
  padding-left: 5px;
  padding-right: 20px;
}

.btnContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: bold;
}

.costContainer {
  padding: 1rem;
}

.mainFlex {
  display: flex;
  flex-wrap: wrap;
}

.storeHolder {
  /* width: 25%; */
  overflow: scroll;
  overflow-x: hidden;
}

.mapContainer {
  width: 73%;
  height: calc(100vh - 80px - 16px);
}

@media (max-width: 900px) {
  .box1 {
    display: none;
  }
}

@media (min-width: 901px) {
  .box2 {
    display: none;
  }
}

.main {
  overflow-y: hidden;
  height: 50%;
}
