.toolBarContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navMenuContainer {
    display: flex;
    background-color: #F5F5F5;
    height:100vh;
    position:fixed;
    width: 100%;
    overflow-y: scroll;
}
.outletContainer {
    padding: 0.5rem;
}