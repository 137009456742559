.loginContainer {
  width: 100%;
  height: 100vh;
  background-image: url("../../../public/auth.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.flexContainer {
  display: flex;
  flex-direction: column;
  /* padding-top: 72px; */
  padding: 72px;
}
.header {
  font-size: 3rem;
  font-weight: bolder;
  color: #424242;
  margin-bottom: 10px;
}
.subHeader {
  font-size: 1.1rem;
  font-weight: 500;
  color: #424242;
  margin-bottom: 8px;
}
.formHolder {
  margin: 16px 0;
}
.textBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formContainer {
  width: 30%;
}
.forgotText {
  font-weight: bolder;
  color: #424242;
}
.termsText {
  font-size: 1rem;
  font-weight: 500;
  /* color: #424242; */
  /* margin-top: 13px; */
}
.error {
  font-weight: 600;
  color: #f44336;
}
@media screen and (max-width: 700px) {
  .formContainer {
    width: 90%;
  }
  .flexContainer {
    padding-left: 30px;
    padding-top: 100px;
  }
}
