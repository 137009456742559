.container {
    /* padding: 10px; */
    width: 100%;
}
.flexContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ratingCard {
    padding: 10px;
}