.container {
  width: 200px;
  height: 200px;
}
.paper {
  cursor: pointer;
}
.btnContainer {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.imageContainer {
  width: 100%;
  height: calc(200px - 34px);
  justify-content: center;
  display: flex;
}
.labelContainer {
  padding: 10px 0;
  text-align: center;
}
