.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-x: auto;
}
.addStoreHeader {
    font-size: 1.5rem;
    text-align: center;
    padding-bottom: 1rem;
    font-weight: 600;
}
.formContainer {
    padding: 10px 0;
}
.textField {
    width: 300px;
}
.tableBtnContainer  * {
    margin: 0 5px !important;
}
.formBtn {
    display: flex;
    gap: 1rem;
}
.headerBtn {
    display: flex;
    gap: 1rem;
}
.searchField {
    width: 300px !important;
}