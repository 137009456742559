.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container {
  margin: 1rem 0;
  cursor: pointer;
  width: 100%;
  max-width: 270px;
  align-self: center;
}
.selected {
  border: 5px solid rgb(255, 255, 255);
  background-color: rgb(150, 227, 150);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 100%;
  max-width: 270px;
}
.notselect {
  border: none;
}

@media (max-width: 375px) {
  .selected {
    border: 5px solid rgb(255, 255, 255);
    background-color: rgb(150, 227, 150);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 100%;
    max-width: 270px;
    /* min-width: 340px; */
    max-height: 290px;
  }
  .notselect {
    border: none;
  }
}
